import React, {
  createContext,
  useState,
  useEffect,
  useContext,
} from 'react'
import {
  fetchAllPlayers,
  fetchFantasyConfig,
  fetchFantasyPlayer,
  fetchFantasyUser,
  fetchPlayersWeeklyData,
  FantasyPlayer,
  CompiledPicksType,
  SnackbarData,
  PlayerWeekFantasyData,
  FantasyPlayerInfo,
  updateFantasyUser,
  submitFantasyTeam,
  FantasyUserWeek,
  FantasyPlayerStats,
} from '../../Api'
import {
  logEvent,
  teamHasPlayerWithGameStarted,
  hasGameStarted,
  checkPlayerCount,
  getCapLeft,
  getTeamBasicData,
  logFirEvent,
  checkCompiled,
  getCurrentWeek,
  selectTotalPoints,
} from '../../Utils'
import { SeasonContext } from '../SeasonContext'
import {
  getPicksArray,
  getPosition,
  selectProjectedPoints,
} from '../../Utils'
import { StorageContext } from '../StorageContext'
import {
  defaultCompiledPicks,
  FANTASY_SALARY_CAP,
  FantasyConfig,
  isChampSeriesLive,
  SEASON,
} from '../../Configs'
import { ANALYTICS_TAGS, STORAGE_NAMES } from '../../Constants'
import { GeneralSnackbar } from '../../Components'
import { AuthContext } from '../../Api/firebase'
import { sortFantasyPlayers, matchPlayerInfo } from '../../Utils'

type FantasyContextType = {
  currentWeek: number
  fantasyConfig: FantasyConfig | null
  fantasyUser: FantasyUserWeek | null
  loading: boolean
  isPlayersLoading: boolean
  playerCardLoading: boolean
  userCap: number
  compiledPicks: CompiledPicksType
  teamHasChanged: boolean
  modalState: { [key: string]: boolean }
  playerCardPlayer: FantasyPlayerStats | undefined
  playerSwapPosition: string | undefined
  allPlayers: FantasyPlayer[]
  projPoints: string
  snackbarData: SnackbarData | null
  teamName: string | null
  weekFantasyData: PlayerWeekFantasyData[]
  allFantasyPlayers: FantasyPlayerInfo[]
  totalWeekPts: number
  removePick: (player: FantasyPlayer) => void
  actionButtonClick: (
    type: string,
    player?: FantasyPlayer,
    position?: string
  ) => void
  addPick: (player: FantasyPlayer) => void
  submitTeam: () => Promise<boolean>
  clearTeam: () => Promise<boolean>
  toggleSnackbar: (message: SnackbarData) => void
  submitTeamName: (teamName: string) => Promise<boolean | undefined>
  handlePlayerSwapClick: () => void
  handleWeekChange: (week: number) => void
  playerCardShow: (playerId?: string | null) => void
  handleShowModal: (modal: string) => void
  setupPicksForWeek: (userTeam?: number[]) => CompiledPicksType
}

export const FantasyContext = createContext<FantasyContextType | null>(
  null
)

export const FantasyProvider: React.FC<
  React.PropsWithChildren<unknown>
> = ({ children }) => {
  let { isLoggedIn, uid, login, isLoading } = useContext(AuthContext)!
  const { isStorageReady, store, getStoredData, deleteStoredData } =
    useContext(StorageContext)!
  let { onWeekDropdownChange, seasonSelect, events, activeWeek } =
    useContext(SeasonContext)!
  const [currentWeek, setCurrentWeek] = useState<number>(
    activeWeek
  )
  const [fantasyConfig, setFantasyConfig] =
    useState<FantasyConfig | null>(null)
  const [fantasyUser, setFantasyUser] =
    useState<FantasyUserWeek | null>(null)
  const [loading, setLoading] = useState<boolean>(true)
  const [isPlayersLoading, setIsPlayersLoading] =
    useState<boolean>(false)
  const [playerCardLoading, setPlayerCardLoading] =
    useState<boolean>(true)
  const [compiledPicks, setCompiledPicks] = useState<CompiledPicksType>(
    defaultCompiledPicks
  )
  const [teamHasChanged, setTeamHasChanged] = useState<boolean>(false)
  const [playerCardPlayer, setPlayerCardPlayer] = useState<
    FantasyPlayerStats | undefined
  >()
  const [playerCardId, setPlayerCardId] = useState<string | null>(null)
  const [playerSwapPosition, setPlayerSwapPosition] = useState<string>()
  const [allPlayers, setAllPlayers] = useState<FantasyPlayer[]>([])
  const [projPoints, setProjPoints] = useState<string>('0')
  const [userCap, setUserCap] = useState<number>(FANTASY_SALARY_CAP)
  const [snackbarData, setSnackbarData] = useState<SnackbarData | null>(
    null
  )
  const [teamName, setTeamName] = useState<string | null>(
    fantasyUser ? fantasyUser.teamName : null
  )
  const [modalState, setModalState] = useState<{
    [key: string]: boolean
  }>({
    showPlayerCard: false,
    showTeamNameSubmit: false,
    showTeamOptions: false,
    showSnackbar: false,
  })
  const [weekFantasyData, setWeekFantasyData] = useState<
    PlayerWeekFantasyData[]
  >([])
  const [allFantasyPlayers, setAllFantasyPlayers] = useState<
    FantasyPlayerInfo[]
  >([])
  const [totalWeekPts, setTotalWeekPts] = useState<number>(0)

  useEffect(() => {
    console.log('fetching fantasy config', isStorageReady)
    getFantasyConfig()
  }, [isStorageReady])

  useEffect(() => {
    if (isLoading) return

    if (!isLoggedIn) {
      getAllFantasyPlayers()
      setLoading(false)
      return
    }
    Promise.all([getFantasyUser(), getAllFantasyPlayers()])
  }, [isLoading, currentWeek, isLoggedIn])

  useEffect(() => {
    if (playerCardId !== null) {
      getSingleFantasyPlayer()
    }
    return
  }, [playerCardId])

  useEffect(() => {
    if (allPlayers.length > 0) {
      setCompiledPicks(setupPicksForWeek(fantasyUser?.fantasyPlayerIds))
      const updatedCap = getCapLeft(
        fantasyConfig?.maxCap ?? FANTASY_SALARY_CAP,
        fantasyUser?.fantasyPlayerIds ?? [],
        allPlayers
      )

      updateTeamState(
        {
          setCompiledPicks,
          setUserCap,
          setProjPoints,
        },
        setupPicksForWeek(fantasyUser?.fantasyPlayerIds),
        updatedCap
      )
    }
  }, [allPlayers, fantasyUser])

  const handleShowModal = (modal: string) => {
    setModalState((prev) => ({
      ...prev,
      [modal]: !prev[modal],
    }))
  }

  const handleWeekChange = (week: number) => {
    onWeekDropdownChange(week)
    week !== currentWeek && setLoading(true)
    setCurrentWeek(week)

    logEvent(ANALYTICS_TAGS.fantasy_week_select, {
      week: week,
    })
  }

  const getFantasyConfig = async () => {
    getCachedConfig()
    const config = await fetchFantasyConfig()
    if (config?.fantasyConfig) {
      setupFantasyConfig(config.fantasyConfig)
      store(STORAGE_NAMES.config_fantasy, config)
    }
  }

  const setupFantasyConfig = (fantasyConfig: FantasyConfig) => {
    setFantasyConfig(fantasyConfig)
    const week = getCurrentWeek(events)
    setCurrentWeek(week ?? 0)

    setUserCap(fantasyConfig?.maxCap ?? FANTASY_SALARY_CAP)
  }

  const getCachedConfig = async (): Promise<void> => {
    const storedConfig = await getStoredData(
      STORAGE_NAMES.config_fantasy
    )
    if (storedConfig?.data) {
      setupFantasyConfig(storedConfig.data)
    }
  }

  const getFantasyUser = async () => {
    if (!uid) {
      setLoading(false)
      return
    }
    const usr = await fetchFantasyUser(currentWeek)
    if (!usr) return setLoading(false)
    usr?.teamName && setTeamName(usr.teamName)
    setFantasyUser(usr)
    setLoading(false)
  }

  const getAllFantasyPlayers = async () => {
    if (isPlayersLoading) return

    const { data } = await getStoredFantasyPlayers()
    if (!data) {
      setIsPlayersLoading(true)
    }

    await allPlayersRefetch()
  }

  const allPlayersRefetch = async () => {
    try {
      const players = await fetchAllPlayers()
      if (!players?.length) {
        setIsPlayersLoading(false)
        deleteStoredData(STORAGE_NAMES.fantasy_all_players)
        return
      }

      setAllFantasyPlayers(players)

      const weeklyData = await fetchPlayersWeeklyData({
        week: currentWeek,
      })
      if (!weeklyData?.length) {
        setIsPlayersLoading(false)
      }

      setWeekFantasyData(weeklyData ?? [])

      // Merge player info with weekly data
      const mergedPlayers = mergeAllPlayersInfo(players, weeklyData)
      const sortedPlayers = sortFantasyPlayers(
        mergedPlayers as FantasyPlayer[],
        1
      )
      setAllPlayers(sortedPlayers)
      store(STORAGE_NAMES.fantasy_all_players, sortedPlayers)
      setIsPlayersLoading(false)
    } catch (err) {
      console.error('Error fetching fantasy players:', err)
      deleteStoredData(`${STORAGE_NAMES.fantasy_all_players}`)
      setIsPlayersLoading(false)
    }
  }

  const mergeAllPlayersInfo = (
    players: FantasyPlayerInfo[],
    weeklyData: PlayerWeekFantasyData[] | null
  ): FantasyPlayer[] | FantasyPlayerInfo[] => {
    if (!weeklyData) return players

    let merged = weeklyData
      .map((player) => {
        const playerInfo = players?.find(
          (p) => p.officialId === player.officialId
        )
        return playerInfo && player.salary > 0
          ? { ...player, ...playerInfo }
          : null
      })
      .filter(
        (player): player is NonNullable<typeof player> =>
          player !== null
      )
    return merged as FantasyPlayer[]
  }

  const getStoredFantasyPlayers = async (): Promise<{
    data: any
    refetch: boolean
  }> => {
    const storedData = await getStoredData(
      `${STORAGE_NAMES.fantasy_all_players}`,
      300000
    )

    if (
      storedData?.data &&
      storedData.data.length > 0 &&
      storedData.data[0].week === currentWeek
    ) {
      const sortedPlayers = sortFantasyPlayers(
        storedData.data as FantasyPlayer[],
        1
      )
      setAllPlayers(sortedPlayers)
      setIsPlayersLoading(false)
      return { data: sortedPlayers, refetch: true }
    }
    return storedData
  }

  const submitTeamName = async (teamName: string) => {
    if (!uid) {
      login()
      return false
    }
    const data = {
      userId: uid,
      teamName: teamName,
    }
    let user = await updateFantasyUser(uid, data)

    if (!user) {
      toggleSnackbar({
        text: 'Unable to update team name.',
        color: 'red',
      })

      return false
    }

    logEvent(ANALYTICS_TAGS.fantasy_create_team, {
      teamName: teamName,
    })

    setTeamName(teamName)
    if (fantasyUser) {
      const newFantUser = {
        ...fantasyUser,
        teamName: teamName,
      }
      setFantasyUser(newFantUser)
    }
    return true
  }

  const setupPicksForWeek = (userTeam?: number[]) => {
    let picks: CompiledPicksType
    if (currentWeek === 0) {
      picks = {
        F: [],
        G: [],
      }
    } else {
      picks = {
        A: [],
        M: [],
        D: [],
        F: [],
        G: [],
      }
    }
    if (!userTeam || allPlayers.length < 1) {
      return picks
    }

    userTeam.map((playerId) => {
      let player = matchPlayerInfo(playerId, allPlayers)
      if (player) {
        if (player.position === 'SSDM' || player.position === 'LSM') {
          picks['D']?.push(player.fantasyPlayerInfoId)
        } else {
          picks[player.position]?.push(player.fantasyPlayerInfoId)
        }
      }
    })

    return picks
  }

  const getTotalWeekPts = (
    picks: CompiledPicksType,
    players: FantasyPlayer[]
  ) => {
    const joinedArrays = getPicksArray(picks)
    const sum = joinedArrays.reduce(
      (acc, pick) => acc + selectTotalPoints(pick, players),
      0
    )
    setTotalWeekPts(sum)
  }

  const removePick = (player: FantasyPlayer) => {
    console.log('removing player', player)
    let position = player.position
    console.log('position', position)
    if (position === 'SSDM' || position === 'LSM') {
      position = 'D'
    }
    if (
      !player ||
      hasGameStarted(player.fantasyPlayerInfoId, events, allPlayers)
    )
      return

    const currentPicks = { ...compiledPicks }
    currentPicks[position] = currentPicks[position].filter(
      (pl: number) => pl !== player.fantasyPlayerInfoId
    )

    const picks = fantasyUser?.fantasyPlayerIds?.filter(
      (id) => id !== player.fantasyPlayerInfoId
    )

    const updatedCap = getCapLeft(
      fantasyConfig?.maxCap ?? FANTASY_SALARY_CAP,
      picks ?? [],
      allPlayers
    )

    updateTeamState(
      {
        setCompiledPicks,
        setUserCap,
        setProjPoints,
        setTeamHasChanged,
      },
      currentPicks,
      updatedCap
    )

    logPlayerAction('remove', player, currentWeek)
  }

  const addPick = (player: FantasyPlayer) => {
    let position = player.position
    if (position === 'SSDM' || position === 'LSM') {
      position = 'D'
    }
    if (!player || !checkRules(position, player)) return

    const currentPicks = { ...compiledPicks }
    currentPicks[player.position].push(player.fantasyPlayerInfoId)

    const picks = fantasyUser?.fantasyPlayerIds?.concat(
      player.fantasyPlayerInfoId
    )

    const updatedCap = getCapLeft(
      fantasyConfig?.maxCap ?? FANTASY_SALARY_CAP,
      picks ?? [],
      allPlayers
    )

    updateTeamState(
      {
        setCompiledPicks,
        setUserCap,
        setProjPoints,
        setTeamHasChanged,
      },
      currentPicks,
      updatedCap
    )

    logPlayerAction('add', player, currentWeek)
  }

  const checkSubmitTeamRules = (picks: CompiledPicksType) => {
    let countPlayersOnTeam = checkPlayerCount(picks)
    let teamCount = Object.values(picks).reduce(
      (acc, curr) => acc + curr.length,
      0
    )
    if (countPlayersOnTeam === 0) {
      //No players selected, return
      toggleSnackbar({
        text: 'No players in lineup',
        color: 'red',
      })
      return false
    }

    if (countPlayersOnTeam < teamCount) {
      //Does not have all players, return
      toggleSnackbar({
        text: `Need exactly ${teamCount} players on team`,
        color: 'red',
      })
      return false
    }
    let underCap =
      getCapLeft(
        fantasyConfig?.maxCap ?? FANTASY_SALARY_CAP,
        fantasyUser?.fantasyPlayerIds ?? [],
        allPlayers
      ) >= 0
    if (!underCap) {
      //Not under cap, return
      toggleSnackbar({
        text: 'You are over the salary cap.',
        color: 'red',
      })
      console.log('Not under cap')
      return false
    }
    return true
  }

  const checkRules = (position: string, player: FantasyPlayer) => {
    if (!checkLimits(position)) {
      toggleSnackbar({
        text: 'Drop a player before adding another.',
        color: 'red',
      })
      return false
    }

    if (currentWeek === 0) {
      if (checkCompiled(compiledPicks, player)) {
        toggleSnackbar({
          text: 'Player is already on team.',
          color: 'red',
        })
        return false
      }
    }

    if (
      hasGameStarted(player.fantasyPlayerInfoId, events, allPlayers)
    ) {
      toggleSnackbar({
        text: `Player's game has already started.`,
        color: 'red',
      })
      return false
    }

    return true
  }

  const actionButtonClick = (
    type: string,
    player?: FantasyPlayer,
    position?: string
  ) => {
    let pos = position ?? player?.position
    if (pos === 'SSDM' || pos === 'LSM') {
      pos = 'D'
    }
    logEvent(ANALYTICS_TAGS.fantasy_action_button_click, {
      player_id: player && player.officialId,
      button_type: type,
      is_logged_in: isLoggedIn,
    })
    if (type === 'arrow') {
      logFirEvent(ANALYTICS_TAGS.fantasy_player_click, {
        week: currentWeek,
        player: player?.officialId,
      })
      return player ? playerCardShow(player.officialId) : null
    }

    switch (type) {
      case 'add':
        return player ? addPick(player) : null
      case 'drop':
        return player ? removePick(player) : null
      case 'swap':
        return handlePlayerSwapClick(pos)
      default:
        return handlePlayerSwapClick(pos)
    }
  }

  const getSingleFantasyPlayer = async () => {
    try {
      const singlePlayer = await fetchFantasyPlayer(
        playerCardId,
        SEASON,
        currentWeek,
        seasonSelect.seasonType
      )

      if (!singlePlayer) return null

      // singlePlayer.allEvents.reverse()
      setPlayerCardPlayer(singlePlayer)
      setPlayerCardLoading(false)
      return
    } catch (err) {
      console.log(err)
      setPlayerCardLoading(false)
      return err
    }
  }

  const submitTeam = async () => {
    // Early returns for validation checks
    if (!fantasyUser) {
      login()
      return false
    }

    if (!teamName) {
      handleShowModal('showTeamNameSubmit')
      return false
    }

    if (!checkSubmitTeamRules(compiledPicks)) {
      return false
    }

    try {
      // Collect all player IDs from compiledPicks into a single array
      const playerIds = Object.values(compiledPicks)
        .flat()
        .map((player) => player)
      const success = await submitFantasyTeam(
        fantasyUser.firebaseId,
        currentWeek,
        playerIds ?? []
      )

      if (!success) {
        toggleSnackbar({
          text: 'There was an error submitting lineup.',
          color: 'red',
        })
        return false
      }

      // Success handling
      setTeamHasChanged(false)
      logFirEvent(ANALYTICS_TAGS.fantasy_team_submit, {
        week: currentWeek,
        players: compiledPicks,
        cap: (fantasyConfig?.maxCap ?? FANTASY_SALARY_CAP) - userCap,
      })

      toggleSnackbar({
        text: 'Success! Team has been saved.',
        color: 'green',
      })

      return true
    } catch (error) {
      console.error('Error submitting team:', error)
      toggleSnackbar({
        text: 'There was an error submitting lineup.',
        color: 'red',
      })
      return false
    }
  }

  const clearTeam = async (): Promise<boolean> => {
    try {
      // Log initial clear attempt
      logEvent(ANALYTICS_TAGS.fantasy_clear_click, {
        week: currentWeek,
      })

      // Check if any games have started
      if (
        teamHasPlayerWithGameStarted(compiledPicks, events, allPlayers)
      ) {
        toggleSnackbar({
          text: 'Cannot clear team - some games have already started',
          color: 'red',
        })
        return false
      }

      // Submit empty team to backend if user is logged in
      if (fantasyUser?.firebaseId) {
        const success = await submitFantasyTeam(
          fantasyUser.firebaseId,
          currentWeek,
          []
        )

        if (!success) {
          toggleSnackbar({
            text: 'There was an error clearing lineup.',
            color: 'red',
          })
          return false
        }
      }

      // Reset all state
      const emptyPicks = Object.fromEntries(
        Object.keys(compiledPicks).map((key) => [key, []])
      ) as CompiledPicksType

      setCompiledPicks(emptyPicks)
      setUserCap(fantasyConfig?.maxCap ?? FANTASY_SALARY_CAP)
      setProjPoints('0.00')
      setTeamHasChanged(false)

      // Log successful clear
      logEvent(ANALYTICS_TAGS.fantasy_team_clear, { week: currentWeek })
      return true
    } catch (error) {
      console.error('Error clearing team:', error)
      toggleSnackbar({
        text: 'An unexpected error occurred while clearing the team.',
        color: 'red',
      })
      return false
    }
  }

  /**
   * Sums up a fantasy user's total week projected points accounting for players that are currently playing
   */
  const getProjPoints = (picks: CompiledPicksType) => {
    let joinedArrays = getPicksArray(picks)
    const sum = joinedArrays.reduce(
      (acc, pick) =>
        acc +
        selectProjectedPoints(pick, allPlayers, isChampSeriesLive),
      0
    )
    return setProjPoints(sum.toFixed(2))
  }

  const updateTeamState = (
    setters: {
      setCompiledPicks?: (picks: CompiledPicksType) => void
      setUserCap?: (cap: number) => void
      setProjPoints?: (points: string) => void
      setTeamHasChanged?: (changed: boolean) => void
    },
    picks: CompiledPicksType,
    cap?: number
  ) => {
    setters.setCompiledPicks && picks && setters.setCompiledPicks(picks)
    if (setters.setUserCap && cap) {
      const totalSalary = getPicksArray(picks).reduce(
        (acc, playerId) => {
          const player = weekFantasyData?.find(
            (p) => p.fantasyPlayerInfoId === playerId
          )
          return acc + (player?.salary ?? 0)
        },
        0
      )
      cap = (fantasyConfig?.maxCap ?? FANTASY_SALARY_CAP) - totalSalary
      setters.setUserCap(cap)
    }
    getProjPoints(picks)
    setters.setTeamHasChanged && setters.setTeamHasChanged(true)
    getTotalWeekPts(picks, allPlayers)
  }

  const checkLimits = (position: string): boolean => {
    // Early returns for invalid inputs
    if (!position || !fantasyConfig?.fantasyWeeks) return false

    const currentFantasyWeek = fantasyConfig.fantasyWeeks.find(
      (week) => week.week === currentWeek
    )
    if (!currentFantasyWeek) return false

    const normalizedPos = getPosition(position).toLowerCase()
    // Get position count from config
    const positionLimits = currentFantasyWeek.playerCount[normalizedPos]
    if (!positionLimits) return false

    const currentPlayerCount = compiledPicks[position].length

    return positionLimits ? currentPlayerCount < positionLimits : false
  }

  const handlePlayerSwapClick = (position?: string) => {
    setPlayerSwapPosition(position ?? undefined)
  }

  const toggleSnackbar = (message: SnackbarData) => {
    setSnackbarData(message)
    setModalState({
      showSnackbar: true,
    })
    setTimeout(() => {
      setModalState({
        showSnackbar: false,
      })
      setSnackbarData(null)
    }, 3000)
  }

  const playerCardShow = (playerId?: string | null) => {
    if (playerId) {
      setPlayerCardId(playerId)
    } else {
      setPlayerCardId(null)
      setPlayerCardPlayer(undefined)
    }

    setPlayerCardLoading(true)
  }

  const logPlayerAction = (
    actionType: string,
    player: FantasyPlayer,
    week: number
  ) => {
    const playerTeam = getTeamBasicData(player.currentTeam.teamId)
    const eventData = {
      player_id: player.officialId,
      player_name: `${player.firstName} ${player.lastName}`,
      player_team: playerTeam?.fullName,
      player_fantasy_game_id: player.fantasyPlayerInfoId,
      player_position: player.position,
      week,
    }

    logEvent(
      actionType === 'add'
        ? ANALYTICS_TAGS.fantasy_player_add
        : ANALYTICS_TAGS.fantasy_player_drop,
      eventData
    )
  }

  return (
    <FantasyContext.Provider
      value={{
        currentWeek,
        fantasyConfig,
        fantasyUser,
        loading,
        isPlayersLoading,
        playerCardLoading,
        userCap,
        modalState,
        compiledPicks,
        teamHasChanged,
        playerCardPlayer,
        playerSwapPosition,
        allPlayers,
        weekFantasyData,
        allFantasyPlayers,
        projPoints,
        totalWeekPts,
        snackbarData,
        teamName,
        removePick,
        actionButtonClick,
        addPick,
        submitTeam,
        clearTeam,
        toggleSnackbar,
        submitTeamName,
        handlePlayerSwapClick,
        handleWeekChange,
        playerCardShow,
        handleShowModal,
        setupPicksForWeek,
      }}
    >
      {children}
      {modalState.showSnackbar && snackbarData && (
        <GeneralSnackbar
          text={snackbarData.text}
          color={snackbarData.color}
          show={modalState.showSnackbar}
        />
      )}
    </FantasyContext.Provider>
  )
}
