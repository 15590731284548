import React, {
  createContext,
  useState,
  useEffect,
  useContext,
  FC,
} from 'react'
import { AuthContext } from '../../Api/firebase'
import {
  Question,
  submitAnswer,
  QuestionParams,
  Competition,
} from '../../Api'
import { SubmitAnswer } from '../../Api/Questions/question.types'
import {
  filterQuestionsByType,
  getQuestionSet,
  isQuestionChangable,
  updateAnswerCounts,
} from '../../Utils'
import { CompetitionContext } from '../CompetitionContext/CompetitionContext'

type QuestionsContextType = {
  loadingQuestions: boolean
  questions: Question[]
  error: QuestionError
  allQuestions: Question[]
  getQuestionSet: (params: QuestionParams) => Promise<Question[]>
  handleSubmitAnswer: (
    question: Question,
    answerCode: string,
    prevAnswerCode?: string
  ) => Promise<SubmitAnswer | null | void>
  checkQuestionCorrect: (q: Question, answerRowCode: string) => number
}
type QuestionsProviderProps = { eventId?: string }
export type QuestionError = {
  [key: string]: {
    questionId: number
    display: boolean
    message: string
  }
} | null

export const QuestionsContext =
  createContext<QuestionsContextType | null>(null)

export const QuestionsProvider: FC<
  React.PropsWithChildren<QuestionsProviderProps>
> = ({ children }) => {
  const { uid, login, isAnonymous, isLoading } =
    useContext(AuthContext)!
  let { selectedContest, updateSummaryByAnswer } =
    useContext(CompetitionContext)!
  const [questions, setQuestions] = useState<Question[]>([])
  const [error, setError] = useState<QuestionError>(null)
  const [allQuestions, setAllQuestions] = useState<Question[]>([])
  const [loadingQuestions, setLoadingQuestions] = useState<boolean>(true)

  useEffect(() => {
    if (isLoading) return
    updateQuestions()
  }, [uid])

  useEffect(() => {
    if (!selectedContest || allQuestions.length < 1) return
    setUpQuestions(allQuestions, selectedContest)
  }, [selectedContest, allQuestions])

  const updateQuestions = async () => {
    const qs = await getQuestionSet(
      {},
      uid
    )
    setAllQuestions(qs)
    if (selectedContest) setUpQuestions(qs, selectedContest)
    setLoadingQuestions(false)
  }

  const setUpQuestions = async (
    qs: Question[],
    selectedComp: Competition
  ) => {
    if (qs.length < 0) return
    let filtered = filterCompetitionQuestions(qs, selectedComp.type)
    setQuestions(filtered)
  }

  const handleSubmitAnswer = async (
    question: Question,
    answerCode: string,
    prevAnswerCode?: string
  ) => {
    if (isAnonymous && question.authRequired) {
      console.log('User is anonymous & auth is required')
      sessionStorage.setItem(
        'login_text',
        'Log in to submit and save your answers to questions'
      )
      return login()
    }

    const currentTime = Math.trunc(Date.now() / 1000)
    if (question.endTime && currentTime > question.endTime) {
      console.log('The question is closed.')
      updateErrorObj(question.id, 'The question is closed.')
      return null
    }

    if (question.userAnswerCode) {
      const canChange = isQuestionChangable(question)
      if (!canChange) {
        console.log('You cannot change your answer.')
        updateErrorObj(question.id, 'You cannot change your answer.')
        return null
      }
    }

    if (question.appExclusive) {
      console.log('App exclusive: Download to participate.')
      updateErrorObj(
        question.id,
        'App exclusive: Download to participate.'
      )
      return null
    }
    if (uid) {
      const success = await submitAnswer(uid, question.id, answerCode)
      if (success) {
        updateAnswerInState(question.questionId, answerCode, prevAnswerCode)
        updateSummaryByAnswer(question.type)
      }
      return success
    }
    console.log('Unknown error on question submit')
    return null
  }

  const updateAnswerInState = (
    questionId: string,
    answer: string,
    prevAnswerCode: string | null = null
  ): boolean => {
    let qs = [...questions]
    const idx = qs.findIndex((q) => q.questionId === questionId)
    if (idx !== -1) {
      let newQ = updateAnswerCounts(qs[idx], answer, prevAnswerCode)
      qs[idx] = newQ
      setQuestions(qs)
      return true
    }
    return false
  }

  const updateErrorObj = async (
    questionId: number,
    message: string
  ) => {
    let errObjs = { ...error }
    let qId = questionId as keyof QuestionError
    errObjs[qId] = {
      questionId: questionId,
      display: true,
      message: message,
    }
    setError(errObjs)
    setTimeout(() => {
      setError(null)
    }, 4000)
  }

  const filterCompetitionQuestions = (
    qs: Question[],
    filterType: string
  ) => {
    let filtered = filterQuestionsByType(qs, filterType)
    setQuestions(filtered)
    return filtered
  }

  const checkQuestionCorrect = (q: Question, answerRowCode: string) => {
    if (!q.userAnswerCode || q.correctAnswer.length < 1) return 0
    if (
      q.userAnswerCode === q.correctAnswer[0] &&
      answerRowCode === q.correctAnswer[0]
    ) {
      return 2
    }
    if (
      q.userAnswerCode !== q.correctAnswer[0] &&
      answerRowCode === q.userAnswerCode
    ) {
      return 1
    }
    return 0
  }

  return (
    <QuestionsContext.Provider
      value={{
        loadingQuestions,
        questions,
        allQuestions,
        error,
        getQuestionSet,
        handleSubmitAnswer,
        checkQuestionCorrect,
      }}
    >
      {children}
    </QuestionsContext.Provider>
  )
}
